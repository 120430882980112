import './media.scss';

// import { loadYoutubeApi } from '../../utilities/js/helper';

class Media {
    constructor (element) {
        this.$media = element;
        this.mediaType = this.$media.getAttribute('data-media');
        this.$videoModul = this.$media.closest('.video-module__inner');
        if (this.$videoModul) {
            this.$mediaText = this.$videoModul.querySelector('.video-module__full-size-text-wrapper');
        }
        this.$video = null;
        this.playing = false;
    }

    initialize () {
        if (this.mediaType === 'youtube') {
            this.$video = this.$media.querySelector('[data-youtube]');
            import('../../utilities/js/youtube-player').then(({ YoutubePlayer }) => {
                this.initYoutubePlayer(YoutubePlayer);
            });
        }

        if (this.mediaType === 'video') {
            this.$video = this.$media.querySelector('video');
            this.initHTML5Player();
        }
    }

    initYoutubePlayer (YoutubePlayer) {
        if (this.$video) {
            const youtubeId = this.$video.getAttribute('data-youtube');

            if (youtubeId !== '') {
                this.parent = null;
                const videoPlayer = new YoutubePlayer(this.$video, {
                    silent: !!this.$video.getAttribute('data-muted'),
                    fullscreen: !!this.$video.getAttribute('data-fullscreen'),
                    loop: !!this.$video.getAttribute('data-loop'),
                    root: this.$media,
                    id: youtubeId,
                    observe: true,
                    onCall: () => {
                        this.$media.classList.add('is--shown');
                    },
                    onStart: (videoId) => {
                        this.$media.classList.add('is--shown');
                        this.$media.classList.remove('is--paused');
                    },
                    onEnd: (videoId) => {
                        if (videoPlayer.player.video.isMuted() === false) {
                            videoPlayer.destroy();
                            this.$media.classList.remove('is--shown');
                            if (this.$mediaText) {
                                this.$mediaText.classList.remove('is--shown');
                            }
                        }
                    },
                    onPause: (videoId, progress) => {
                        this.playing = false;

                        this.$media.classList.add('is--paused');

                        if (this.$mediaText) {
                            this.$mediaText.classList.remove('is--shown');
                        }
                    },
                    onStateChange: (event) => {
                        if (event === 0) {
                            if (this.$mediaText) {
                                this.$media.classList.add('is--paused');
                                this.$mediaText.classList.remove('is--shown');
                            }
                        }

                        if (event === 1) {
                            this.$media.classList.remove('is--paused');
                            if (this.$mediaText) {
                                this.$mediaText.classList.add('is--shown');
                            }
                        }
                    }
                });
            }
        }
    }

    initHTML5Player () {
        const autoplay = !!this.$video.getAttribute('autoplay');
        const loop = !!this.$video.getAttribute('loop');

        if (autoplay) {
            this.$media.classList.add('is--playing');
            this.$video.load();
        }

        if (!loop) {
            this.$video.addEventListener('ended', () => {
                this.$media.classList.remove('is--playing');
            });
        }

        if (this.$media.classList.contains('has--poster')) {
            this.$media.querySelector('.media__play').addEventListener('click', () => {
                this.$media.classList.add('is--playing');
                this.$video.play();
            });
        }
    }
}

export { Media };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $medias = $context.querySelectorAll('[data-media]');

        if ($medias.length > 0) {
            // const waitForConsent = setInterval(() => {
            //     if (typeof CookieConsent !== 'undefined') {
            //         if (CookieConsent.consent.marketing === true) { // eslint-disable-line
            window.onYouTubeIframeAPIReady = () => {
                if ($medias.length > 0) {
                    $medias.forEach((media) => {
                        const $media = new Media(media);
                        $media.initialize();
                    });
                }
            };
            /*
            window.onYouTubeIframeAPIReady = () => {};
            */
            //         } else {
            //             window.addEventListener('CookiebotOnAccept', () => {
            //                 if (CookieConsent.consent.marketing) { // eslint-disable-line
            //                     location.reload();
            //                 }
            //             }, false);
            //         }
            //         clearInterval(waitForConsent);
            //     } else {
            //         console.log('waiting for CookieContent');
            //     }
            // }, 300);
        }
    }
});
